@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import "~bootstrap/dist/css/bootstrap.css";

$klusfixr-blue: #0029FF;
$klusfixr-green: #96FB2A;

html {
  overflow-x: hidden;
  font-family: Verdana;
}

.klusfixr-green-hr {
  background-color: $klusfixr-green;
}
